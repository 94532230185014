var wipwrp = window.wipwrp || {};

wipwrp.fiera = (function($) {

    var self = {};
    self.init = function () {
        _initRepeater();
        $('.form_prodotti button.btn-1.salva').click(function (e) {
            e.preventDefault();
            if(wipwrp.utils.checkMerceologie(4)) {
                $('.form_prodotti').submit();
            }
        })

        var fotoStand = document.querySelector('#foto_stand');
        wipwrp.imageCropper.init(fotoStand, {
            image: fotoStand.dataset.image,
            inputFile: document.querySelector('input[name="foto_stand"]'),
            inputFileOld: document.querySelector('input[name="foto_stand_old"]'),
            inputCropX: document.querySelector('input[name="foto_stand_crop_x"'),
            inputCropY: document.querySelector('input[name="foto_stand_crop_y"'),
            inputCropW: document.querySelector('input[name="foto_stand_crop_w"'),
            inputCropH: document.querySelector('input[name="foto_stand_crop_h"'),
            infoBtn: document.querySelector("#foto_stand_info_btn")
        });


		wipwrp.commons.initCkEditor('#storia_attivita');
    }

    function _initRepeater() {
        $('.repeater').repeater({
            isFirstItemUndeletable: true,
            show: function () {
                $(this).find('.descrizione_prodotti').val('');
                $(this).show();
            }
        })

        var firstRow =  $('.repeater').find('[data-repeater-item]').first();
        var firstRowInput = firstRow.find('input');
        var formProdotti = $('.form_prodotti');

        firstRow.addClass('required');
        formProdotti.parsley().destroy();
        firstRowInput.prop('required',true);
        firstRowInput.attr('data-parsley-required', 'true');
        formProdotti.parsley();
    }

    return self;
})(jQuery);
